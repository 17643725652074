.intro {
  margin: 0 5.5rem 8rem;
  padding-top: 8rem;
  height: 892px;
}

.intro-title {
  justify-content: center;
  color: var(--black);
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
}

.intro-subtitle {
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  text-align: center;
  color: #000000;
}

.intro-content {
  justify-content: center;
  display: flex;
}

.intro-left {
  position: relative;
  width: 375px;
  height: 367px;
  margin: 5rem 0rem;
}

.time-ic {
  position: relative;
  left: 55px;
  top: 100px;
  display: flex;
  margin-bottom: 1rem;
}

.time-desc {
  margin-left: 1.5rem;
  width: 355px;
  height: 76px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: justify;
  color: #000000;
}

.check-ic {
  position: relative;
  left: 55px;
  top: 100px;
  display: flex;
  margin-bottom: 1rem;
}

.check-desc {
  margin-left: 1.5rem;
  width: 355px;
  height: 76px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: justify;
  color: #000000;
}

.true-ic {
  position: relative;
  left: 55px;
  top: 100px;
  display: flex;
  margin-bottom: 1rem;
}

.true-desc {
  margin-left: 1.5rem;
  width: 355px;
  height: 76px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: justify;
  color: #000000;
}

.button-start {
  position: absolute;
  width: 206px;
  height: 48px;
  top: 785px;
  background: #36c8f6;
  border: 1px solid #36c8f6;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
}

.button-start:hover {
  cursor: pointer;
  box-shadow: 0px 4px 10px 0px rgba(40, 40, 124, 0.7);
  transition: all 0.2s ease 0s;
  transform: scale(1.05);
}

@media (max-width: 481px) {
  .intro {
    height: 892px;
  }

  .intro-title {
    position: absolute;
    top: 5rem;
    left: 50%;
    transform: translate(-50%);
    width: 22rem;
    font-weight: 600;
    font-size: 1.8rem !important;
    line-height: 44px;
    text-align: center;
    padding: 0rem !important;
  }

  .intro-subtitle {
    margin-top: 4rem;
    justify-content: center;
    width: 300px;
    position: absolute;
    left: 50%;
    line-height: 22px;
    transform: translate(-50%);
    padding: 0rem !important;
  }

  .intro-left {
    display: none;
  }

  .intro-right {
    margin-top: 12rem;
    width: 300px;
  }

  .time-ic {
    left: 5px;
    margin-bottom: 2rem;
  }

  .check-ic {
    left: 5px;
    margin-bottom: 2rem;
  }

  .true-ic {
    left: 5px;
    margin-bottom: 2rem;
  }
}

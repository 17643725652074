.navbar {
  width: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  padding-top: 1.5rem;
  font-size: 1.1rem;
  box-shadow: rgb(0 0 0 / 5%) 0px 5px 10px;
  background-color: var(--white);
  z-index: 1000;
  border: none;
}

.n-left {
  display: flex;
  justify-content: left;
  font-size: 24px;
  font-weight: bold;
  color: var(--blue);
}

.n-right {
  display: flex;
  justify-content: right;
  flex: 1;
}

.btn-login {
  border-radius: 6px;
  background-color: var(--blue);
  border: 2px solid var(--blue);
  padding: 6px 0;
  width: 111px;
  font-size: 14px;
  font-weight: bold;
  height: 41px;
}

.btn-register {
  margin-right: 1.3rem;
  border-radius: 6px;
  background-color: var(--white);
  border: 1px solid var(--blue);
  padding: 6px 0;
  width: 111px;
  font-size: 14px;
  height: 41px;
  font-weight: bold;
  color: var(--blue);
}

.btn-login:hover {
  cursor: pointer;
  box-shadow: 0px 4px 10px 0px rgba(40, 40, 124, 0.7);
  transition: all 0.2s ease 0s;
  transform: scale(1.05);
}

.btn-register:hover {
  cursor: pointer;
  box-shadow: 0px 4px 10px 0px rgba(40, 40, 124, 0.7);
  transition: all 0.2s ease 0s;
  transform: scale(1.05);
}

.btn-logout {
  margin-left: 15px;
}

.logout {
  border-radius: 6px;
  padding: 6px 0;
  font-size: 18px;
  font-weight: bold;
  color: black;
  height: 41px;
}

.logout:hover {
  cursor: pointer;
  transition: all 0.2s ease 0s;
  transform: scale(1.05);
  color: red;
}

nav a {
  text-decoration: none;
  color: var(--blue);
  font-size: inherit;
  display: unset;
}

.navbar-nav {
  margin-left: 2rem;
}

@media (max-width: 481px) {
  .navbar {
    position: inherit;
    padding-top: 0.3rem !important;
  }

  .navbar-nav {
    margin-left: 0rem;
  }

  .n-right {
    display: flex;
    justify-content: flex-start;
  }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
  }

  .btn-logout {
    display: none;
  }
}

.dashboard {
  margin: 0 5.5rem 4rem;
  padding-top: 2.8rem;
}

.table-admin th,
.table-admin td {
  color: black;
  background-color: #e8f0fe;
}

.baris:hover {
  cursor: pointer;
  box-shadow: 0px 4px 10px 0px rgba(40, 40, 124, 0.7);
  transition: all 0.2s ease 0s;
  transform: scale(1.01);
}

.login-admin {
  margin: 0 5.5rem 8rem;
  padding-top: 3rem;
  justify-content: center;
  display: flex;
  height: 445px;
}

.login-admin-logo {
  position: relative;
  width: 528px;
  height: 377px;
  left: 3px;
  top: 10px;
}

.login-admin-box {
  position: relative;
  margin-left: 5rem;
  width: 500px;
  height: 464px;
  background: #ffffff;
  box-shadow: 5px 5px 20px rgb(0 0 0 / 25%);
  border-radius: 10px;
}

.typeResult {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 670px;
  margin-top: 4rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  color: #000000;
}

.button-right {
  margin-right: 1.5rem;
  border-radius: 6px;
  background-color: var(--blue);
  border: 1px solid var(--black);
  width: 111px;
  font-size: 14px;
  height: 41px;
  font-weight: bold;
  float: right;
  top: 135px;
  right: 170px;
}

.button-right:hover {
  cursor: pointer;
  box-shadow: 0px 4px 10px 0px rgba(40, 40, 124, 0.7);
  transition: all 0.2s ease 0s;
  transform: scale(1.05);
}
.evaluasi{

  text-align: center;
  position:absolute;
  /* display: flex; */
  margin: auto;
}

@media (max-width: 481px) {
  .dashboard {
    margin: 2rem 2rem;
    padding-top: 5rem;
    height: 892px;
  }

  .type-result {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 260px;
    width: 100%;
    margin-top: 4rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
    color: #000000;
  }

  .search {
    margin-top: 9rem !important;
    margin-bottom: 0rem !important;
  }

  .table-admin {
    margin-top: 3rem;
  }

  .login-admin {
    margin: 0rem;
    padding-top: 0rem;
    justify-content: center;
    display: grid;
    height: 900px;
  }
  .login-admin-box {
    position: absolute;
    width: 340px;
    left: 50%;
    top: 292px;
    transform: translate(-50%);
    height: 496px;
    margin-left: 0rem;
    background: #ffffff;
    box-shadow: 5px 5px 20px rgb(0 0 0 / 25%);
    border-radius: 10px;
  }

  .login-admin-logo {
    position: absolute;
    width: 213px;
    height: 168px;
    left: 50%;
    top: 97px;
    transform: translate(-50%);
  }

  .typeResult {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 46px;
    width: 325px;
    margin-top: 4rem;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
    color: #000000;
  }
}

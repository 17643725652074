.resultPage {
  width: 100%;
  height: 108rem;
}

.bg {
  width: 100%;
}

.resultSection {
  position: absolute;
  width: 1150px;
  height: 620px;
  left: 50%;
  transform: translate(-50%);
  top: 124px;
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 2;
}

.intro-user-result {
  position: absolute;
  width: 615px;
  height: 53px;
  left: 50%;
  transform: translate(-50%);
  top: 50px;
}

.hi-user {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 479px;
  top: 30px;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
}

.type-result {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 22px;
  width: 685px;
  margin-top: 4rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  color: #000000;
}

.user-result {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 172px;
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  color: #000000;
}

.characteristic-desc {
  position: absolute;
  width: 400px;
  height: 31px;
  margin-left: 5rem;
  top: 235px;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
}

.characteristic-points {
  position: absolute;
  margin: 4rem;
  height: 227px;
  top: 220px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: justify;
  color: #000000;
  list-style-type: disc;
}

.title-jobSection {
  justify-content: center;
  color: var(--black);
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
}

.jobSection {
  position: relative;
  height: 655px;
  top: 780px;
}

.jobCarousel {
  position: relative;
  height: 747px;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: inherit;
}

.jobs-box {
  position: absolute;
  width: 1150px;
  left: 50%;
  transform: translate(-50%);
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  margin-top: 1rem;
  height: 670px;
}

.title-job-box {
  width: 328px;
  height: 255px;
  margin: 2rem;
}

.jobs-ic {
  width: 119px;
  height: 117px;
  margin: 2rem 6.5rem;
}

.title-jobs {
  padding: 0rem 1rem;
  top: 11rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.desc-job-box {
  width: 766px;
  height: 500px;
  left: 528px;
  top: 150px;
}

.description-points {
  font-weight: 550;
  line-height: 22px;
  font-size: 18px;
  text-align: justify;
  text-indent: 2px;
  color: #000000;
  list-style-type: disc;
  padding: 0rem 3rem;
}


.btn-back {
    box-sizing: border-box;
    position: absolute;
    width: 396px;
    height: 60px;
    position: relative;
    margin-left: 3rem;
    margin-top: 2rem;
    background: #36c8f6;
    border: 1px solid #36c8f6;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
  }

  .btn-back:hover {
    cursor: pointer;
    box-shadow: 0px 4px 10px 0px rgba(40, 40, 124, 0.7);
    transition: all 0.2s ease 0s;
    transform: scale(1.05);
  }

/*untuk layar device berukuran kecil*/
@media (max-width: 481px) {
  .btn-back {
    width: 275px;
    height: 60px;
    margin: 1rem 2rem;
    top: 3rem;
    left: 0px;
  }
  .resultPage {
    width: 100%;
    height: 197rem;
  }

  .bg {
    width: 100%;
    height: 530px;
  }

  .resultSection {
    position: absolute;
    width: 340px;
    height: 1435px;
    left: 50%;
    transform: translate(-50%);
    top: 124px;
    background: #ffffff;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    z-index: 2;
  }

  .intro-user-result {
    position: absolute;
    width: 100%;
    height: 53px;
    left: 50%;
    transform: translate(-50%);
    top: -225px;
  }

  .hi-user {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    top: 257px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    line-height: 31px;
    padding: 0rem 0.5rem;
    color: #000000;
  }

  .type-result {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 260px;
    width: 100%;
    margin-top: 4rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
    color: #000000;
  }

  .user-result {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 164px;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
    width: 100%;
    padding: 0rem 1rem;
    color: #000000;
  }

  .characteristic-desc {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 226px;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
    width: 100%;
    color: #000000;
    margin-left: 0rem;
  }

  .characteristic-points {
    position: absolute;
    margin: 1rem 1.5rem !important;
    height: 227px;
    top: 260px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: justify;
    color: #000000;
    list-style-type: disc;
  }

  .jobSection {
    position: relative;
    top: 1526px;
  }

  .jobCarousel {
    position: relative;
    height: 1377px;
  }

  .carousel-control-next,
  .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }

  .title-jobSection {
    justify-content: center;
    color: var(--black);
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 31px;
    text-align: center;
    padding: 0rem 1.5rem;
  }

  .jobs-box {
    position: absolute;
    width: 340px;
    left: 50%;
    transform: translate(-50%);
    background: #ffffff;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    margin-top: 1rem;
    height: 82rem;
  }

  .title-job-box {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }

  .jobs-ic {
    width: 119px;
    height: 117px;
    margin: 1rem 4.5rem;
  }

  .title-jobs {
    width: 264px;
    padding: 0rem !important;
  }

  .desc-job-box {
    width: 766px;
    height: 500px;
    margin-top: 15rem;
    text-align: justify;
  }

  .description-points {
    font-weight: 550;
    line-height: 22px;
    font-size: 18px;
    text-align: justify;
    text-indent: 2px;
    color: #000000;
    list-style-type: disc;
    padding: 0rem 3rem;
  }
}

.main-footer {
  width: 100%;
  margin-top: -8rem;
  background-color: rgba(230, 230, 250, 0.37);
}

.logo-footer {
  width: 300px;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: var(--blue);
}

.col {
  margin: 1rem;
}

.copyrights {
  text-align: center;
  margin: 1rem;
  height: 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--black);
}

.footer-desc {
  width: 277px;
}

@media (max-width: 481px) {
  .main-footer {
    width: 100%;
    background-color: rgba(230, 230, 250, 0.37);
  }

  .logo-footer {
    margin: 0.2rem 0rem;
    width: 300px;
    font-weight: 700;
    font-size: 28px;
    line-height: 48px;
    color: var(--blue);
  }

  .copyrights {
    text-align: center;
    margin: 1rem;
    height: 24px;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: var(--black);
  }
}

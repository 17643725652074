.login {
  margin: 0 5.5rem 8rem;
  padding-top: 8rem;
  justify-content: center;
  display: flex;
  height: 740px;
}

.login-logo {
  position: relative;
  width: 570px;
  height: 440px;
  left: 3px;
  top: 60px;
}

.login-box {
  position: relative;
  margin-left: 5rem;
  width: 500px;
  height: 542px;
  background: #ffffff;
  box-shadow: 5px 5px 20px rgb(0 0 0 / 25%);
  border-radius: 10px;
}

.loginreg-title {
  width: 377px;
  margin: auto;
  margin-top: 2.5rem;
  line-height: 39px;
  font-weight: bold;
  text-align: center;
  color: #000000;
}

.loginreg-sub-title {
  justify-content: center;
  margin: 6px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #000000;
}

.form-icon {
  margin: 18px;
}

.show-pwd {
  margin-right: 1rem;
}

.email-form {
  width: 395px;
  height: 60px;
  margin: 3rem 3rem 1rem;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  color: #d9b9c2;
  border: 1px solid #36c8f6;
  border-radius: 10px;
}

.login-input {
  height: 55px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: black;
  border: 0px none;
  border-radius: 10px;
  outline: 0px !important;
  box-shadow: none !important;
}

.password-form {
  width: 395px;
  height: 60px;
  margin: 1rem 3rem;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  color: #d9b9c2;
  border: 1px solid #36c8f6;
  border-radius: 10px;
}

.form-check {
  position: absolute;
  width: 110px;
  left: 60px;
  top: 305px;
}

.form-check-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #000000;
}

.wrong {
  position: absolute;
  left: 12rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: red;
}

.btn-masuk {
  box-sizing: border-box;
  position: absolute;
  width: 396px;
  height: 60px;
  position: relative;
  margin-left: 3rem;
  margin-top: 2rem;
  background: #36c8f6;
  border: 1px solid #36c8f6;
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
}

.btn-masuk:hover {
  cursor: pointer;
  box-shadow: 0px 4px 10px 0px rgba(40, 40, 124, 0.7);
  transition: all 0.2s ease 0s;
  transform: scale(1.05);
}

.create-account {
  justify-content: center;
  margin: 2rem;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #000000;
}

@media (max-width: 481px) {
  .login {
    margin: 0rem;
    padding-top: 0rem;
    justify-content: center;
    display: grid;
    height: 900px;
  }

  .login-logo {
    position: absolute;
    width: 213px;
    height: 168px;
    left: 50%;
    top: 97px;
    transform: translate(-50%);
  }

  .login-box {
    position: absolute;
    width: 340px;
    left: 50%;
    top: 292px;
    transform: translate(-50%);
    height: 496px;
    margin-left: 0rem;
    background: #ffffff;
    box-shadow: 5px 5px 20px rgb(0 0 0 / 25%);
    border-radius: 10px;
  }

  .loginreg-sub-title {
    justify-content: center;
    margin: 5px 9px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #000000;
  }

  .email-form {
    width: 275px;
    height: 60px;
    margin: 1rem 2rem;
    top: 0rem;
    left: 0px;
  }

  .password-form {
    width: 275px;
    height: 60px;
    margin: 1rem 2rem;
    top: 0rem;
    left: 0px;
  }

  .wrong {
    position: absolute;
    top: 21rem;
    left: 3rem;
  }

  .btn-masuk {
    width: 275px;
    height: 60px;
    margin: 1rem 2rem;
    top: 3rem;
    left: 0px;
  }

  .create-account {
    position: absolute;
    top: 26rem;
  }

  .form-check {
    left: 50px;
  }
}

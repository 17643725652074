.job-section {
  padding-top: 5rem;
  background-color: rgba(230, 230, 250, 0.37);
  width: 100%;
  height: 1160px;
  z-index: 1;
}

.title-job-section {
  justify-content: center;
  color: var(--black);
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
}

.job-category1 {
  margin-top: 38px;
  justify-content: center;
  display: flex;
}

.job-category2 {
  margin-top: 5px;
  justify-content: center;
  display: flex;
}

.job-category3 {
  margin-top: 5px;
  justify-content: center;
  display: flex;
}

.job-category-card {
  width: 272px;
  height: 268px;
  margin: 8px;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.job-category-card:hover {
  box-shadow: 0px 4px 10px 0px rgba(40, 40, 124, 0.7);
  transform: scale(1.05);
}

.btn-more {
  margin-left: 8.5rem;
  border-radius: 6px;
  background-color: var(--white);
  border: 1px solid var(--blue);
  width: 122px;
  height: 41px;
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.btn-more:hover {
  cursor: pointer;
  background-color: var(--bright-cyan);
  border: 1px solid var(--bright-cyan);
  color: white;
}

.job-icon {
  width: 52px;
  height: 51px;
  margin: 6px;
}

.job-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin: 13px;
}

.job-description {
  font-style: normal;
  font-weight: semi-bold;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  margin: 13px;
}

.description {
  font-weight: 550;
  line-height: 22px;
  font-size: 18px;
  color: #000000;
  padding: 2rem 2rem 0rem 2rem;
}

.description-point {
  font-weight: 550;
  line-height: 22px;
  font-size: 18px;
  text-align: justify;
  text-indent: 2px;
  color: #000000;
  list-style-type: disc;
  padding-right: 2rem;
}

@media (max-width: 481px) {
  .job-section {
    padding-top: 5rem;
    background-color: rgba(230, 230, 250, 0.37);
    width: 100%;
    height: 82rem;
  }

  .title-job-section {
    position: absolute;
    top: 43rem;
    left: 50%;
    transform: translate(-50%);
    width: 22rem;
    font-weight: 600;
    font-size: 1.8rem !important;
    line-height: 44px;
    text-align: center;
    padding: 0rem !important;
  }

  .job-category1 {
    margin: 159px 2rem 0rem;
    overflow-y: scroll;
    width: 85%;
    justify-content: flex-start;
  }

  .job-category1::-webkit-scrollbar {
    width: 12px;
  }

  .job-category1::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  .job-category2 {
    margin: 7px 2rem 0rem;
    overflow-y: scroll;
    width: 85%;
    justify-content: flex-start;
  }

  .job-category2::-webkit-scrollbar {
    width: 12px;
  }

  .job-category2::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  .job-category3 {
    margin: 7px 2rem 0rem;
    overflow-y: scroll;
    width: 85%;
    justify-content: flex-start;
  }

  .job-category3::-webkit-scrollbar {
    width: 12px;
  }

  .job-category3::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  .job-category-card {
    width: 272px;
    height: 315px;
  }

  .job-category-card:hover {
    box-shadow: 0px 4px 10px 0px rgba(40, 40, 124, 0.7);
    transform: none;
  }

  .btn-more {
    margin-right: 1rem;
    margin-left: 4.5rem;
    width: 122px;
    height: 41px;
    font-size: 14px;
    font-weight: bold;
  }

  .description {
    font-weight: 550;
    line-height: 22px;
    font-size: 18px;
    color: #000000;
    padding: 1rem 1rem 0rem 1rem;
  }

  .description-point {
    font-weight: 550;
    line-height: 22px;
    font-size: 18px;
    text-align: justify;
    text-indent: 2px;
    color: #000000;
    list-style-type: disc;
    padding-right: 0.5rem;
  }

  .description-points {
    font-weight: 550;
    line-height: 22px;
    font-size: 18px;
    text-align: justify;
    text-indent: 2px;
    color: #000000;
    list-style-type: disc;
    padding: 0rem 1rem 0rem 2rem !important;
  }
}

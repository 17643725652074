* {
  color: rgb(255 255 255);
  padding: 0;
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --bright-cyan: #36c8f6;
  --blue: #0000ff;
  --black: #000000;
  --white: #ffffff;
  --lavender: #e8f0fe;
}

ul {
  list-style: none;
  margin: 0;
  color: var(--black);
}

Link {
  text-decoration: none;
  color: var(--black);
}

li {
  color: var(--black);
}

a {
  text-decoration: none;
}

hr {
  margin: 0 !important;
  border: 0;
  color: black !important;
  border-top: 1px solid;
}

button {
  border: none;
  background-color: white;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: 2px solid #36c8f6;
  padding: 2px;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0000ff;
}

.register {
  margin: 0 5.5rem 8rem;
  padding-top: 8rem;
  justify-content: center;
  display: flex;
  height: 740px;
}

.register-logo {
  position: relative;
  width: 570px;
  height: 440px;
  left: 3px;
  top: 60px;
}

.register-box {
  position: relative;
  margin-left: 5rem;
  width: 500px;
  height: 542px;
  background: #ffffff;
  box-shadow: 5px 5px 20px rgb(0 0 0 / 25%);
  border-radius: 10px;
}

.loginreg-title {
  width: 377px;
  margin: auto;
  margin-top: 2.5rem;
  line-height: 39px;
  font-weight: bold;
  text-align: center;
  color: #000000;
}

.loginreg-sub-title {
  justify-content: center;
  margin: 6px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #000000;
}

.form-icon {
  margin: 10px;
}

.show-pwd {
  margin: 1.3rem 0.5rem;
}

.register-input {
  height: 55px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: black;
  border: 0px none;
  border-radius: 10px;
  outline: 0px !important;
  box-shadow: none !important;
}

.name-register-form {
  width: 188px;
  height: 60px;
  margin: 3rem 3rem 1rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #d9b9c2;
  border: 1px solid #36c8f6;
  border-radius: 10px;
}

.name2-register-form {
  width: 188px;
  height: 60px;
  left: 255px;
  top: -76px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #d9b9c2;
  border: 1px solid #36c8f6;
  border-radius: 10px;
}

.email-register-form {
  width: 395px;
  height: 60px;
  margin: 1rem 3rem;
  top: -5rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #d9b9c2;
  border: 1px solid #36c8f6;
  border-radius: 10px;
}

.password-register-form {
  width: 188px;
  height: 60px;
  margin: 1rem 3rem;
  top: -5rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #d9b9c2;
  border: 1px solid #36c8f6;
  border-radius: 10px;
}

.password-confirm-register-form {
  width: 188px;
  height: 60px;
  left: 255px;
  top: -156px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #d9b9c2;
  border: 1px solid #36c8f6;
  border-radius: 10px;
}

.min-password {
  position: absolute;
  top: 23.5rem;
  left: 3rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: red;
}

.btn-daftar {
  box-sizing: border-box;
  position: absolute;
  width: 396px;
  height: 60px;
  top: -6.699999999999999rem;
  position: relative;
  margin-left: 3rem;
  background: #36c8f6;
  border: 1px solid #36c8f6;
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
}

.btn-daftar:hover {
  cursor: pointer;
  box-shadow: 0px 4px 10px 0px rgba(40, 40, 124, 0.7);
  transition: all 0.2s ease 0s;
  transform: scale(1.05);
}

.login-account {
  position: absolute;
  top: 31rem;
  margin: 0rem !important;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  left: 50%;
  width: 279px;
  transform: translate(-50%, -50%);
  color: #000000;
}

.show-pwd-ic {
  border-radius: 10px;
}

.show-pwd-ic:hover {
  cursor: pointer;
  text-decoration: none !important;
}

@media (max-width: 481px) {
  .register {
    margin: 0rem;
    padding-top: 0rem;
    justify-content: center;
    display: grid;
    height: 1094px;
  }

  .register-logo {
    position: absolute;
    width: 213px;
    height: 168px;
    left: 50%;
    top: 97px;
    transform: translate(-50%);
  }

  .register-box {
    position: absolute;
    width: 340px;
    left: 50%;
    top: 292px;
    transform: translate(-50%);
    height: 688px;
    margin-left: 0rem;
    background: #ffffff;
    box-shadow: 5px 5px 20px rgb(0 0 0 / 25%);
    border-radius: 10px;
  }

  .loginreg-sub-title {
    justify-content: center;
    margin: 5px 9px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #000000;
  }

  .name-register-form {
    width: 275px;
    height: 60px;
    margin: 1rem 2rem;
  }

  .name2-register-form {
    width: 275px;
    height: 60px;
    margin: 1rem 2rem;
    top: 0rem;
    left: 0px;
  }

  .email-register-form {
    width: 275px;
    height: 60px;
    margin: 1rem 2rem;
    top: 0rem;
    left: 0px;
  }

  .password-register-form {
    width: 275px;
    height: 60px;
    margin: 1rem 2rem;
    top: 0rem;
    left: 0px;
  }

  .password-confirm-register-form {
    width: 275px;
    height: 60px;
    margin: 1rem 2rem;
    top: 0rem;
    left: 0px;
  }

  .min-password {
    position: absolute;
    top: 32.5rem;
    left: 2.5rem;
  }

  .btn-daftar {
    width: 275px;
    height: 60px;
    margin: 1rem 2rem;
    top: 0.5rem;
    left: 0px;
  }

  .login-account {
    top: 40rem;
  }
}
